import * as React from 'react';
import Heading from '../components/heading';
import Layout from '../components/layout';
import Text from '../components/text';
import map from 'lodash/map';
import AnimateIn from '../components/animate-in';
import SEO from '../components/seo';
import './travel.scss';

const data = {
    title: 'Travel Information',
    what_to_do: {
        title: 'Food, drink, and activities',
        body: (
            <>
                <b>Our venue is located in the beautiful North Oak Cliff</b>, an
                area that is very near and dear to our hearts! There is so much
                to do all over Dallas –{' '}
                <i>
                    here are some of our favorite walkable and hip neighborhoods
                    to explore!
                </i>
            </>
        ),
        recGroups: [
            {
                name: 'Lower Greenville',
                recs: [
                    {
                        name: 'Wabi House',
                        description: 'Our favorite ramen in Dallas',
                        url: 'https://g.page/WabiHouseDallas?share',
                    },
                    {
                        name: 'Tacos La Banqueta Puro DF',
                        description: 'The best tacos in all of Dallas',
                        url: 'https://goo.gl/maps/zkUeypr8kPNbacKf7',
                    },
                    {
                        name: 'Truck Yard',
                        description: 'Quirky outdoor beer garden',
                        url: 'https://goo.gl/maps/2WwjsevP8YSFcJGC7',
                    },
                    {
                        name: 'HG Sply Co.',
                        description:
                            'Restaurant and bar with a fun rooftop patio',
                        url: 'https://goo.gl/maps/c9fbz7cXo64avxhg8',
                    },
                    {
                        name: 'Milk + Cream',
                        description:
                            'Donut ice cream sandwiches, need we say more?',
                        url: 'https://goo.gl/maps/7T95oycy196hkrYz9',
                    },
                    {
                        name: 'Botolino Gelato Artigianale',
                        description: 'Mind-blowing gelato',
                        url: 'https://goo.gl/maps/CDPw9L6Yswid8WAA7',
                    },
                    {
                        name: 'La La Land Kind Cafe',
                        description: 'Photogenic coffee shop with a good cause',
                        url: 'https://goo.gl/maps/vY6Tjydu3HMyFPjf9',
                    },
                    {
                        name: 'The Merchant',
                        description: 'Cute gift store and coffee shop',
                        url: 'https://goo.gl/maps/88Wz4UBzV1E59m5x6',
                    },
                    {
                        name: 'Trader Joe’s',
                        description: 'If you know, you know',
                        url: 'https://goo.gl/maps/RWvuPPRJcPsD7Nd2A',
                    },
                ],
            },
            {
                name: 'Bishop Arts District',
                recs: [
                    {
                        name: 'Eno’s Pizza',
                        description:
                            'No description necessary it’s freakin pizza',
                        url: 'https://goo.gl/maps/7G7cHTX9rpS6kNuG7',
                    },
                    {
                        name: 'Oddfellows',
                        description: 'A hip, rustic brunch diner',
                        url: 'https://goo.gl/maps/qmjWesMG8iVXzdsi9',
                    },
                    {
                        name: 'Emporium Pies',
                        description:
                            'Local specialty pie shop. Truly a Dallas staple.',
                        url: 'https://goo.gl/maps/YZ9davqjPRZmtKwQ9',
                    },
                    {
                        name: 'Azucar Ice Cream Company',
                        description: 'You have to get the "Abuela Maria"',
                        url: 'https://g.page/azucaricecreamdallas?share',
                    },
                    {
                        name: 'Dude, Sweet Chocolate',
                        description: 'Local chocolatier with unique flavors',
                        url:
                            'https://g.page/dude-sweet-chocolate-bishop-arts?share',
                    },
                    {
                        name: 'Wild Detectives',
                        description:
                            'Books, coffee, and alcohol. What more could Maddy need?',
                        url: 'https://goo.gl/maps/iTuKjiraqasdfj137',
                    },
                    {
                        name: 'Mosaic Maker’s Collective',
                        description: 'Handmade women-designed goods',
                        url: 'https://goo.gl/maps/btcw7Rb7V6JJcLJE9',
                    },
                    {
                        name: 'Spinster Records',
                        description: 'Indie vinyl record store',
                        url: 'https://goo.gl/maps/xW9uTtzT3hx9bALd7',
                    },
                    {
                        name: 'White Rock Soap Gallery',
                        description: 'Handmade soaps and candles',
                        url: 'https://goo.gl/maps/cBUYfyo9R3cpSenv5',
                    },
                ],
            },
            {
                name: 'Deep Ellum',
                recs: [
                    {
                        name: 'Invasion Eatery',
                        description: 'The best chicken sandwich in Dallas',
                        url: 'https://g.page/invasion-dallas?share',
                    },
                    {
                        name: 'Oni Ramen',
                        description: 'A small but delicious ramen shop',
                        url: 'https://goo.gl/maps/XU2UVUqj8GuwjidbA',
                    },
                    {
                        name: 'Emporium Pies',
                        description:
                            'Local specialty pie shop. Truly a Dallas staple.',
                        url: 'https://goo.gl/maps/otNvRsMxRpVRA93XA',
                    },
                    {
                        name: 'Jeni’s Ice Cream',
                        description: 'Yummy yummy sugar cream',
                        url: 'https://g.page/jenis-deep-ellum?share',
                    },
                    {
                        name: 'Thunderbird Station',
                        description: 'The most unique cocktails we’ve ever had',
                        url: 'https://goo.gl/maps/aHMFeyG88CXKGGGbA',
                    },
                    {
                        name: 'Trinity Cider',
                        description: 'Sophisticated craft cidery',
                        url: 'https://g.page/trinitycider?share',
                    },
                    {
                        name: 'Jade & Clover',
                        description: 'Plants, gifts, and good vibes',
                        url: 'https://goo.gl/maps/AbHwtd2oHj1ANzXF9',
                    },
                    {
                        name: 'Archer Paper Goods',
                        description: 'Beautiful cards, stationary, and gifts',
                        url: 'https://goo.gl/maps/RPCxy2gqUpLQBTVJ7',
                    },
                ],
            },
            {
                name: 'Uptown',
                recs: [
                    {
                        name: 'Sixty Vines',
                        description: 'Wine and farm-to-table cuisine',
                        url: 'https://goo.gl/maps/DXvMEHp1ZXrMqWrf9',
                    },
                    {
                        name: 'Katy Trail Ice House',
                        description:
                            'Outdoor ice house on the historic Katy Trail',
                        url: 'https://goo.gl/maps/atG6hsAmwmAnLetE7',
                    },
                    {
                        name: 'The Rustic',
                        description: 'Restaurant, bar, and music venue',
                        url: 'https://goo.gl/maps/QtTjTxWHH9sxJgXR6',
                    },
                    {
                        name: 'Taqueria La Ventana',
                        description: 'Laid-back street-inspired taco stand',
                        url: 'https://goo.gl/maps/KvgfJTujXURPUgB29',
                    },
                    {
                        name: 'Dallas Farmer’s Market',
                        description: 'A hub of local vendors and merchants',
                        url: 'https://g.page/DFMDallasFarmersMarket_?share',
                    },
                    {
                        name: 'Klyde Warren Park ',
                        description: 'Cute park in the center of downtown',
                        url: 'https://goo.gl/maps/rXxdsuqAd3dwt8Fw9',
                    },
                    {
                        name: 'The M Line Trolley',
                        description: 'A nostalgic way to get around',
                        url: 'https://goo.gl/maps/LLNaZn8yLBJrAXqXA',
                    },
                ],
            },
        ],
    },
};

const Recommendation = ({ recommendation }) => {
    const description = recommendation?.description
        ? ` – ${recommendation?.description}`
        : '';
    return (
        <li>
            <Text size="medium">
                <a
                    href={recommendation.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="secondary-hover"
                >
                    {recommendation?.name}
                </a>
                <i>{description}</i>
            </Text>
        </li>
    );
};

const RecommendationGroup = ({ recGroup }) => {
    return (
        <div>
            <Heading level={3} size={4}>
                {recGroup.name}
            </Heading>
            <ul>
                {map(recGroup.recs, (rec) => (
                    <Recommendation recommendation={rec} key={rec.name} />
                ))}
            </ul>
        </div>
    );
};

// markup
const AccomodationsPage = () => {
    return (
        <Layout className="accomodations-page">
            <SEO title="Accomodations" slug="accomodations" />
            <AnimateIn onScroll>
                <Heading level={1}>{data.title}</Heading>
                <Heading level={2}>{data.what_to_do.title}</Heading>
                <Text size="large">{data.what_to_do.body}</Text>
                <div className="recommendations">
                    {map(data.what_to_do.recGroups, (recGroup) => (
                        <RecommendationGroup
                            recGroup={recGroup}
                            key={recGroup.name}
                        />
                    ))}
                </div>
            </AnimateIn>
        </Layout>
    );
};

export default AccomodationsPage;
